// src/modules/admin/layout/AdminLayout.js
import React, { useState } from 'react';
import { Layout } from 'antd';
import Topbar from './Topbar';
import CustomContent from './Content';
import Sidemenu from './SideMenu';

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem('kubo_admin_token');
    window.location.href = '/admin/login';
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidemenu collapsed={collapsed} />
      <Layout className="site-layout">
        <Topbar collapsed={collapsed} toggle={toggle} handleLogout={handleLogout} />
        <CustomContent />
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
