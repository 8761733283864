// src/modules/admin/layout/Sidemenu.js
import React from 'react';
import { Layout, Menu } from 'antd';
import { UnorderedListOutlined, ShoppingOutlined, FileTextOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const Sidemenu = ({ collapsed }) => {
  const navigate = useNavigate();

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" style={{ color: 'white', padding: '20px', textAlign: 'center' }}>
        Kubo Admin
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={({ key }) => navigate(key)}>
        <Menu.Item key="/admin/fournisseurs" icon={<UnorderedListOutlined />}>
          Fournisseurs
        </Menu.Item>
        <Menu.Item key="/admin/categories" icon={<UnorderedListOutlined />}>
          Catégories
        </Menu.Item>
        <Menu.Item key="/admin/products" icon={<ShoppingOutlined />}>
          Produits
        </Menu.Item>
        <Menu.Item key="/admin/orders" icon={<FileTextOutlined />}>
          Commandes
        </Menu.Item>
        <Menu.Item key="/admin/bordereaux" icon={<FileTextOutlined />}>
          Bordereaux
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidemenu;
