// src/modules/client/layout/Content.jsx
import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const ContentComponent = () => (
  <Content style={{
    marginTop: '64px', // margin to be under the top menu
    marginBottom: '64px', // margin to be above the footer
    backgroundColor: 'transparent',
    padding: 0,
    minHeight: 'calc(100vh - 128px)',
    overflow: 'auto',
  }}>
    <div style={{ minHeight: 'calc(100vh - 128px)' }}>
      <Outlet />
    </div>
  </Content>
);

export default ContentComponent;
