// src/modules/client/layout/Footer.jsx
import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => (
  <Footer style={{ textAlign: 'center', backgroundColor: 'transparent', position: 'fixed', bottom: 0, width: '100%' }}>
    <p style={{ color: '#fff' }}>© 2024, KUBO.TN. Tous droits réservés.</p>
    <p style={{ color: '#fff' }}>Développé par KREATEK.tn</p>
  </Footer>
);

export default FooterComponent;
