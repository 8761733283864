// src/modules/admin/pages/Products.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber, Popconfirm, message, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../config/axiosInstance';
import { API_URL } from '../../../config/api';

const { Option } = Select;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchFournisseurs();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/produits');
      setProducts(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des produits.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/categories');
      setCategories(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des catégories.');
    }
  };

  const fetchFournisseurs = async () => {
    try {
      const response = await axiosInstance.get('/fournisseurs');
      setFournisseurs(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des fournisseurs.');
    }
  };

  const handleAdd = () => {
    setEditingProduct(null);
    form.resetFields();
    setImageUrl(null);
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditingProduct(record);
    form.setFieldsValue(record);
    setImageUrl(record.image || null);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/produits/${id}`);
      message.success('Produit supprimé avec succès.');
      fetchProducts();
    } catch (error) {
      message.error('Erreur lors de la suppression du produit.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (imageUrl) {
        values.image = imageUrl;
      }
      if (editingProduct) {
        await axiosInstance.put(`/produits/${editingProduct.id}`, values);
        message.success('Produit modifié avec succès.');
      } else {
        await axiosInstance.post('/produits', values);
        message.success('Produit ajouté avec succès.');
      }
      fetchProducts();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Erreur lors de la sauvegarde du produit.');
    }
  };

  const handleUploadChange = ({ file }) => {
    if (file.status === 'done') {
      setImageUrl(file.response.filePath);
      message.success('Image téléchargée avec succès.');
    } else if (file.status === 'error') {
      message.error('Erreur lors du téléchargement de l\'image.');
    }
  };

  const columns = [
    {
      title: 'Libellé',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text) => text ? <img src={text} alt="Produit" style={{ width: '50px' }} /> : 'Aucune image'
    },
    {
      title: 'Prix',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Quantité',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Promotion',
      dataIndex: 'promotion',
      key: 'promotion',
      render: (text, record) => (
        <div>
          {text} {record.nature_promotion === 'percentage' ? '%' : 'DT'}
        </div>
      ),
    },
    {
      title: 'Commission Kubo',
      dataIndex: 'commission_kubo',
      key: 'commission_kubo',
      render: (text, record) => (
        <div>
          {text} {record.nature_commission_kubo === 'percentage' ? '%' : 'DT'}
        </div>
      ),
    },
    {
      title: 'Réduction Kubo',
      dataIndex: 'discount_kubo',
      key: 'discount_kubo',
      render: (text, record) => (
        <div>
          {text} {record.nature_reduction_kubo === 'percentage' ? '%' : 'DT'}
        </div>
      ),
    },
    {
      title: 'Catégorie',
      dataIndex: 'category_id',
      key: 'category_id',
      render: (text) => {
        const category = categories.find((cat) => cat.id === text);
        return category ? category.label : 'Non assigné';
      },
    },
    {
      title: 'Fournisseur',
      dataIndex: 'id_fournisseur',
      key: 'id_fournisseur',
      render: (text) => {
        const fournisseur = fournisseurs.find((f) => f.id === text);
        return fournisseur ? fournisseur.raison_sociale : 'Non assigné';
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>Modifier</Button>
          <Popconfirm title="Êtes-vous sûr de vouloir supprimer ce produit?" onConfirm={() => handleDelete(record.id)}>
            <Button type="link" danger>Supprimer</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Ajouter un produit
      </Button>
      <Table
        columns={columns}
        dataSource={products}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title={editingProduct ? 'Modifier Produit' : 'Ajouter Produit'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="label"
            label="Libellé"
            rules={[{ required: true, message: 'Veuillez saisir le libellé du produit!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
          >
            <Upload
              name="image"
              listType="picture"
              action={`${API_URL}/produits/upload`}
              accept="image/*"
              onChange={handleUploadChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Télécharger l'image</Button>
            </Upload>
            {imageUrl && <img src={imageUrl} alt="Produit" style={{ width: '100%', marginTop: 10 }} />}
          </Form.Item>
          <Form.Item
            name="price"
            label="Prix"
            rules={[{ required: true, message: 'Veuillez saisir le prix du produit!' }]}
          >
            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantité"
            rules={[{ required: true, message: 'Veuillez saisir la quantité du produit!' }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Promotion"
          >
            <Input.Group compact>
              <Form.Item
                name="promotion"
                noStyle
                rules={[{ required: true, message: 'Veuillez saisir la promotion du produit!' }]}
              >
                <InputNumber min={0} step={0.01} style={{ width: '70%' }} />
              </Form.Item>
              <Form.Item
                name="nature_promotion"
                noStyle
                initialValue="value"
              >
                <Select style={{ width: '30%' }}>
                  <Option value="value">DT</Option>
                  <Option value="percentage">Pourcentage</Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            label="Commission Kubo"
          >
            <Input.Group compact>
              <Form.Item
                name="commission_kubo"
                noStyle
                rules={[{ required: true, message: 'Veuillez saisir la commission Kubo du produit!' }]}
              >
                <InputNumber min={0} step={0.01} style={{ width: '70%' }} />
              </Form.Item>
              <Form.Item
                name="nature_commission_kubo"
                noStyle
                initialValue="value"
              >
                <Select style={{ width: '30%' }}>
                  <Option value="value">DT</Option>
                  <Option value="percentage">Pourcentage</Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            label="Réduction Kubo"
          >
            <Input.Group compact>
              <Form.Item
                name="discount_kubo"
                noStyle
                rules={[{ required: true, message: 'Veuillez saisir la réduction Kubo du produit!' }]}
              >
                <InputNumber min={0} step={0.01} style={{ width: '70%' }} />
              </Form.Item>
              <Form.Item
                name="nature_reduction_kubo"
                noStyle
                initialValue="value"
              >
                <Select style={{ width: '30%' }}>
                  <Option value="value">DT</Option>
                  <Option value="percentage">Pourcentage</Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name="category_id"
            label="Catégorie"
            rules={[{ required: true, message: 'Veuillez sélectionner une catégorie!' }]}
          >
            <Select placeholder="Sélectionnez une catégorie">
              {categories.map(category => (
                <Option key={category.id} value={category.id}>{category.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="id_fournisseur"
            label="Fournisseur"
            rules={[{ required: true, message: 'Veuillez sélectionner un fournisseur!' }]}
          >
            <Select placeholder="Sélectionnez un fournisseur">
              {fournisseurs.map(fournisseur => (
                <Option key={fournisseur.id} value={fournisseur.id}>{fournisseur.raison_sociale}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Products;
