// src/modules/client/pages/HomePage.jsx
import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="fade-in" style={{
      textAlign: 'center',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 128px)', // Subtract the height of the fixed header and footer
    }}>
      <img src="/kubo.png" alt="Logo" style={{ marginBottom: '20px' }} height={150} />
      <p style={{ fontSize: '24px', margin: '16px 0' }}>Articles artisanaux pour ceux qui se soucient</p>
      <Button type="primary" size="large" style={{
        backgroundColor: '#1A0D03',
        borderColor: '#1A0D03',
        fontSize: '16px',
        padding: '10px 20px',
      }} onClick={() => navigate('/shop')}>
        ACHETER MAINTENANT
      </Button>
    </div>
  );
};

export default HomePage;
