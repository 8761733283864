// src/modules/client/pages/ProductsPage.jsx
import React from 'react';

const ProductsPage = () => (
  <div>
    <h1>Our Products</h1>
    <p>Explore our wide range of artisanal products.</p>
  </div>
);

export default ProductsPage;
