// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import './modules/client/layout/Topbar.css';
import ProtectedAdminRoute from './modules/admin/pages/ProtectedAdminRoute';
import AdminLogin from './modules/admin/pages/AdminLogin';
import AdminIndex from './modules/admin/layout/AdminIndex';
import ClientLayout from './modules/client/layout/ClientLayout';
import HomePage from './modules/client/pages/HomePage';
import ProductsPage from './modules/client/pages/ProductsPage';
import AboutPage from './modules/client/pages/AboutPage';
import ContactPage from './modules/client/pages/ContactPage';
import ShopPage from './modules/client/pages/ShopPage';
import ProfilePage from './modules/client/pages/ProfilePage';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/*" element={
            <ProtectedAdminRoute>
              <AdminIndex />
            </ProtectedAdminRoute>
          } />
          <Route path="/" element={<ClientLayout />}>
            <Route index element={<HomePage />} />
            <Route path="products" element={<ProductsPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="shop" element={<ShopPage />} />
            <Route path="profile" element={<ProfilePage />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
