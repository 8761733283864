// src/modules/client/pages/ProfilePage.jsx
import React, { useEffect, useState } from 'react';
import { List, message } from 'antd';
import axiosInstance from '../../../config/axiosInstance';

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState({});
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchProfile();
    fetchOrders();
  }, []);

  const fetchProfile = async () => {
    try {
      const client = JSON.parse(localStorage.getItem('client'));
      setUserInfo(client);
    } catch (error) {
      message.error('Erreur lors de la récupération des informations utilisateur.');
    }
  };

  const fetchOrders = async () => {
    try {
      const client = JSON.parse(localStorage.getItem('client'));
      const response = await axiosInstance.get(`/api/client/orders?clientId=${client.id}`);
      setOrders(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des commandes.');
    }
  };

  return (
    <div className="container mt-4">
      <h2>Profil de l'utilisateur</h2>
      <p><strong>Nom et prénom:</strong> {userInfo.name}</p>
      <p><strong>Téléphone:</strong> {userInfo.phoneNumber}</p>
      <p><strong>Adresse:</strong> {userInfo.address}</p>

      <h2>Mes commandes</h2>
      <List
        itemLayout="horizontal"
        dataSource={orders}
        renderItem={order => (
          <List.Item>
            <List.Item.Meta
              title={`Commande #${order.id}`}
              description={`Statut: ${order.statut}, Date: ${order.date_commande}`}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default ProfilePage;
