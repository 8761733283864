// src/modules/client/components/CategoryMenu.jsx
import React from 'react';
import { Card, Menu } from 'antd';

const CategoryMenu = ({ categories, selectedCategory, onSelectCategory }) => {
  const items = categories.map(category => ({
    key: category.id,
    label: category.label,
  }));

  return (
    <Card>
      <Menu
      mode="inline"
      selectedKeys={[selectedCategory]}
      style={{ height: '100%', borderRight: 0 }}
      items={items}
      onClick={(e) => onSelectCategory(e.key)}
    />
    </Card>
  );
};

export default CategoryMenu;
