// src/modules/admin/layout/AdminIndex.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import Categories from '../pages/Categories';
import Products from '../pages/Products';
import Orders from '../pages/Orders';
import Bordereaux from '../pages/Bordereaux';
import Fournisseurs from '../pages/Fournisseurs';

const AdminIndex = () => (
  <Routes>
    <Route path="/" element={<AdminLayout />}>
      <Route path="categories" element={<Categories />} />
      <Route path="products" element={<Products />} />
      <Route path="orders" element={<Orders />} />
      <Route path="bordereaux" element={<Bordereaux />} />
      <Route path="fournisseurs" element={<Fournisseurs />} />
    </Route>
  </Routes>
);

export default AdminIndex;
