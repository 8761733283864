// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedAdminRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('kubo_admin_token');

  return isAuthenticated ? children : <Navigate to="/admin/login" />;
};

export default ProtectedAdminRoute;
