// src/modules/admin/pages/AdminLogin.js
import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../config/axiosInstance';

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/auth/admin', values);
      localStorage.setItem('kubo_admin_token', response.data.token);
      message.success('Connexion réussie!');
      navigate('/admin');
    } catch (error) {
      message.error('Échec de la connexion. Veuillez vérifier vos identifiants et réessayer.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '2rem' }}>
      <h2>Connexion Admin</h2>
      <Form name="login" onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Veuillez saisir votre nom d\'utilisateur!' }]}
        >
          <Input placeholder="Nom d'utilisateur" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Veuillez saisir votre mot de passe!' }]}
        >
          <Input.Password placeholder="Mot de passe" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Connexion
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminLogin;
