// src/modules/client/components/ProductList.jsx
import React from 'react';
import { Card, Button, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, updateCart, removeFromCart } from '../../../reducers/cartReducer';

const ProductList = ({ products, selectedCategory }) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);

  const filteredProducts = selectedCategory
    ? products.filter(product => product.category_id == selectedCategory)
    : products;

  const isInCart = (productId) => cart.some(item => item.id === productId);

  return (
    <div className="container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <div className="row">
        {filteredProducts.map(product => (
          <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
            <Card title={product.label} cover={<img alt={product.label} src={product.image} height={200} style={{ objectFit: 'cover' }} />} style={{ minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div>
                <p>{product.description}</p>
                <p>Prix: {product.price} TND</p>
              </div>
              {isInCart(product.id) ? (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button type="link" danger onClick={() => dispatch(removeFromCart(product.id))}>Supprimer</Button>
                  <InputNumber min={1} value={cart.find(item => item.id === product.id)?.quantity} onChange={(value) => dispatch(updateCart({ id: product.id, quantity: value }))} />
                </div>
              ) : (
                <Button type="primary" onClick={() => dispatch(addToCart(product))}>Ajouter au panier</Button>
              )}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
