// src/modules/client/pages/ContactPage.jsx
import React from 'react';
import { Form, Input, Button } from 'antd';

const ContactPage = () => (
  <div style={{ padding: '20px', backgroundColor: '#FFFFFF', textAlign: 'center' }}>
    <h1>Formulaire de Contact</h1>
    <Form layout="vertical" style={{ maxWidth: '600px', margin: '0 auto' }}>
      <Form.Item label="Nom et prénom">
        <Input placeholder="Votre nom et prénom" />
      </Form.Item>
      <Form.Item label="Email">
        <Input placeholder="Votre email" />
      </Form.Item>
      <Form.Item label="Téléphone">
        <Input placeholder="Votre téléphone" />
      </Form.Item>
      <Form.Item label="Adresse">
        <Input placeholder="Votre adresse" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Envoyer</Button>
      </Form.Item>
    </Form>
  </div>
);

export default ContactPage;
