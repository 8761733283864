// src/modules/client/components/CartDropdown.jsx
import React, { useState } from 'react';
import { Dropdown, Button, InputNumber, Modal, Form, Input, message, Menu } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart, removeFromCart, clearCart } from '../../../reducers/cartReducer';
import axiosInstance from '../../../config/axiosInstance';

const CartDropdown = ({ onValidateCart }) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({ phoneNumber: '', name: '', address: '' });
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const handleValidateCart = () => {
    const isLoggedIn = false; // Replace with actual login check
    if (!isLoggedIn) {
      setIsModalVisible(true);
    } else {
      onValidateCart();
      dispatch(clearCart());
    }
  };

  const handleUserSubmit = async () => {
    try {
      const signupResponse = await axiosInstance.post('/api/client/signup', userInfo);

      if (signupResponse.data.existingClient) {
        setUserInfo(signupResponse.data.existingClient);
      }

      await axiosInstance.post('/api/client/send-verification', { phoneNumber: userInfo.phoneNumber });
      message.success('Un code de vérification a été envoyé à votre téléphone.');
    } catch (error) {
      message.error('Erreur lors de la soumission des informations utilisateur.');
    }
  };

  const handleVerificationSubmit = async () => {
    try {
      const response = await axiosInstance.post('/api/client/verify-code', { phoneNumber: userInfo.phoneNumber, verificationCode });
      if (response.data.message === 'Code de vérification correct.') {
        setIsVerified(true);
        setIsModalVisible(false);
        
        // Save client info in local storage
        localStorage.setItem('client', JSON.stringify(response.data.client));
        
        // Insert order into the database
        const orderResponse = await axiosInstance.post('/api/client/orders', {
          clientId: response.data.client.id,
          orders: cart
        });

        if (orderResponse.status === 201) {
          onValidateCart();
          dispatch(clearCart());
          window.location.href = '/profile'; // Redirect to profile page
        } else {
          message.error('Erreur lors de l\'enregistrement de la commande.');
        }
      } else {
        message.error('Code de vérification incorrect.');
      }
    } catch (error) {
      message.error('Erreur lors de la vérification du code.');
    }
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const totalAmount = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  const menuItems = cart.length > 0 
    ? cart.map(item => ({
        key: item.id,
        label: (
          <div style={{ display: 'flex', alignItems: 'center', minHeight: '50px' }} onClick={handleStopPropagation}>
            <img src={item.image} alt={item.label} style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }} />
            <span>{item.label}</span>
            <InputNumber min={1} value={item.quantity} onChange={(value) => dispatch(updateCart({ id: item.id, quantity: value }))} style={{ marginLeft: '10px' }} />
            <span style={{ marginLeft: '10px' }}>{(item.price * item.quantity).toFixed(2)} TND</span>
            <Button type="link" danger onClick={() => dispatch(removeFromCart(item.id))}>Supprimer</Button>
          </div>
        )
      }))
    : [{ key: 'empty', label: <span>Le panier est vide</span> }];

  menuItems.push({
    key: 'total',
    label: (
      <div style={{ textAlign: 'right', padding: '10px 0' }}>
        <strong>Total: {totalAmount.toFixed(2)} TND</strong>
      </div>
    )
  });

  menuItems.push({
    key: 'validate',
    label: (
      <Button type="primary" onClick={handleValidateCart} style={{ width: '100%' }}>Valider le panier</Button>
    )
  });

  return (
    <>
      <Dropdown
        trigger={['click']}
        menu={{ items: menuItems }}
        overlayClassName="cart-dropdown"
      >
        <Button type="primary" icon={<ShoppingCartOutlined />} style={{ marginLeft: 'auto' }}>Panier</Button>
      </Dropdown>
      <Modal
        title="Informations utilisateur"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>Annuler</Button>,
          <Button key="submit" type="primary" onClick={handleUserSubmit}>Soumettre</Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Nom et prénom">
            <Input value={userInfo.name} onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })} />
          </Form.Item>
          <Form.Item label="Téléphone">
            <Input value={userInfo.phoneNumber} onChange={(e) => setUserInfo({ ...userInfo, phoneNumber: e.target.value })} />
          </Form.Item>
          <Form.Item label="Adresse">
            <Input value={userInfo.address} onChange={(e) => setUserInfo({ ...userInfo, address: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Vérification du code"
        open={isVerified}
        onCancel={() => setIsVerified(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsVerified(false)}>Annuler</Button>,
          <Button key="submit" type="primary" onClick={handleVerificationSubmit}>Vérifier</Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Code de vérification">
            <Input value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CartDropdown;
