// src/modules/client/layout/Topbar.jsx
import { UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CartDropdown from '../components/CartDropdown';

const { Header } = Layout;

const Topbar = ({ onValidateCart }) => {
  const navigate = useNavigate();
  const cart = useSelector(state => state.cart);

  const items = [
    { key: 'home', label: 'Accueil', onClick: () => navigate('/') },
    { key: 'shop', label: 'Boutique', onClick: () => navigate('/shop') },
    { key: 'about', label: 'À propos', onClick: () => navigate('/about') },
    { key: 'contact', label: 'Contact', onClick: () => navigate('/contact') },
    { key: 'profile', icon: <UserOutlined style={{ color: 'white' }} />, onClick: () => navigate('/profile') },
  ];

  return (
    <Header className="topbar">
      <Menu theme="light" mode="horizontal" items={items} className="topbar-menu" />
      <CartDropdown cart={cart} onValidateCart={onValidateCart} />
    </Header>
  );
};

export default Topbar;
