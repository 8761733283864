// src/modules/admin/layout/Topbar.js
import React from 'react';
import { Layout, Dropdown, Button, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { Header } = Layout;

const Topbar = ({ collapsed, toggle, handleLogout }) => {
    const userMenu = (
        <Menu>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                Déconnexion
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="site-layout-background" style={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#fff' }}>
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={toggle}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                }}
            />
            <Dropdown overlay={userMenu}>
                <Button type="text" icon={<UserOutlined />} style={{ marginRight: 16 }}>
                    Admin
                </Button>
            </Dropdown>
        </Header>
    );
};

export default Topbar;
