// src/modules/client/layout/ClientLayout.jsx
import React from 'react';
import { Layout } from 'antd';
import Topbar from './Topbar';
import ContentComponent from './Content';
import FooterComponent from './Footer';
import CustomConfigProvider from './ConfigProvider';

const ClientLayout = () => (
  <CustomConfigProvider>
    <Layout style={{ minHeight: '100vh', background: 'url("https://i0.wp.com/www.althemist.com/zass/wp-content/uploads/2018/09/zass_dark_hero1.jpg?fit=1920%2C1040&") no-repeat center center', backgroundSize: 'cover' }}>
      <Topbar />
      <ContentComponent />
      <FooterComponent />
    </Layout>
  </CustomConfigProvider>
);

export default ClientLayout;

