// src/modules/client/pages/ShopPage.jsx
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import CategoryMenu from '../components/CategoryMenu';
import ProductList from '../components/ProductList';
import axiosInstance from '../../../config/axiosInstance';

const ShopPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/categories');
      setCategories(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des catégories.');
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('/produits');
      setProducts(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des produits.');
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-3">
          <CategoryMenu categories={categories} selectedCategory={selectedCategory} onSelectCategory={setSelectedCategory} />
        </div>
        <div className="col-md-9">
          <ProductList products={products} selectedCategory={selectedCategory} />
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
