// src/modules/admin/pages/Categories.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, message } from 'antd';
import axiosInstance from '../../../config/axiosInstance';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/categories');
      setCategories(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des catégories.');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setEditingCategory(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditingCategory(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/categories/${id}`);
      message.success('Catégorie supprimée avec succès.');
      fetchCategories();
    } catch (error) {
      message.error('Erreur lors de la suppression de la catégorie.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (editingCategory) {
        await axiosInstance.put(`/categories/${editingCategory.id}`, values);
        message.success('Catégorie modifiée avec succès.');
      } else {
        await axiosInstance.post('/categories', values);
        message.success('Catégorie ajoutée avec succès.');
      }
      fetchCategories();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Erreur lors de la sauvegarde de la catégorie.');
    }
  };

  const columns = [
    {
      title: 'Libellé',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>Modifier</Button>
          <Popconfirm title="Êtes-vous sûr de vouloir supprimer cette catégorie?" onConfirm={() => handleDelete(record.id)}>
            <Button type="link" danger>Supprimer</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Ajouter une catégorie
      </Button>
      <Table
        columns={columns}
        dataSource={categories}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title={editingCategory ? 'Modifier Catégorie' : 'Ajouter Catégorie'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="label"
            label="Libellé"
            rules={[{ required: true, message: 'Veuillez saisir le libellé de la catégorie!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Categories;
