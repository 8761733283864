// src/modules/admin/pages/Fournisseur.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../config/axiosInstance';
import { API_URL } from '../../../config/api';

const Fournisseurs = () => {
  const [fournisseurs, setFournisseurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingFournisseur, setEditingFournisseur] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [patenteUrl, setPatenteUrl] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchFournisseurs();
  }, []);

  const fetchFournisseurs = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/fournisseurs');
      setFournisseurs(response.data);
    } catch (error) {
      message.error('Erreur lors de la récupération des fournisseurs.');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setEditingFournisseur(null);
    form.resetFields();
    setPhotoUrl(null);
    setPatenteUrl(null);
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditingFournisseur(record);
    form.setFieldsValue(record);
    setPhotoUrl(record.photo || null);
    setPatenteUrl(record.patente || null);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/fournisseurs/${id}`);
      message.success('Fournisseur supprimé avec succès.');
      fetchFournisseurs();
    } catch (error) {
      message.error('Erreur lors de la suppression du fournisseur.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (photoUrl) {
        values.photo = photoUrl;
      }
      if (patenteUrl) {
        values.patente = patenteUrl;
      }
      if (editingFournisseur) {
        await axiosInstance.put(`/fournisseurs/${editingFournisseur.id}`, values);
        message.success('Fournisseur modifié avec succès.');
      } else {
        await axiosInstance.post('/fournisseurs', values);
        message.success('Fournisseur ajouté avec succès.');
      }
      fetchFournisseurs();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Erreur lors de la sauvegarde du fournisseur.');
    }
  };

  const handlePhotoUploadChange = ({ file }) => {
    if (file.status === 'done') {
      setPhotoUrl(file.response.filePath);
      message.success('Photo téléchargée avec succès.');
    } else if (file.status === 'error') {
      message.error('Erreur lors du téléchargement de la photo.');
    }
  };

  const handlePatenteUploadChange = ({ file }) => {
    if (file.status === 'done') {
      setPatenteUrl(file.response.filePath);
      message.success('Patente téléchargée avec succès.');
    } else if (file.status === 'error') {
      message.error('Erreur lors du téléchargement de la patente.');
    }
  };

  const columns = [
    {
      title: 'Raison Sociale',
      dataIndex: 'raison_sociale',
      key: 'raison_sociale',
    },
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (text) => text ? <img src={text} alt="Fournisseur" style={{ width: '50px' }} /> : 'Aucune photo'
    },
    {
      title: 'Patente',
      dataIndex: 'patente',
      key: 'patente',
      render: (text) => text ? <a href={text} target="_blank" rel="noopener noreferrer">Voir la patente</a> : 'Aucune patente'
    },
    {
      title: 'Matricule Fiscal',
      dataIndex: 'matricule_fiscal',
      key: 'matricule_fiscal',
    },
    {
      title: 'Téléphone',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Page Facebook',
      dataIndex: 'page_facebook',
      key: 'page_facebook',
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>Modifier</Button>
          <Popconfirm title="Êtes-vous sûr de vouloir supprimer ce fournisseur?" onConfirm={() => handleDelete(record.id)}>
            <Button type="link" danger>Supprimer</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Ajouter un fournisseur
      </Button>
      <Table
        columns={columns}
        dataSource={fournisseurs}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title={editingFournisseur ? 'Modifier Fournisseur' : 'Ajouter Fournisseur'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="raison_sociale"
            label="Raison Sociale"
            rules={[{ required: true, message: 'Veuillez saisir la raison sociale du fournisseur!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="photo"
            label="Photo"
          >
            <Upload
              name="photo"
              listType="picture"
              action={`${API_URL}/fournisseurs/upload`}
              accept="image/*"
              onChange={handlePhotoUploadChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Télécharger la photo</Button>
            </Upload>
            {photoUrl && <img src={photoUrl} alt="Fournisseur" style={{ width: '100%', marginTop: 10 }} />}
          </Form.Item>
          <Form.Item
            name="patente"
            label="Patente"
          >
            <Upload
              name="patente"
              listType="text"
              action={`${API_URL}/fournisseurs/upload`}
              accept="application/pdf"
              onChange={handlePatenteUploadChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Télécharger la patente</Button>
            </Upload>
            {patenteUrl && <a href={patenteUrl} target="_blank" rel="noopener noreferrer">Voir la patente</a>}
          </Form.Item>
          <Form.Item
            name="matricule_fiscal"
            label="Matricule Fiscal"
            rules={[{ required: true, message: 'Veuillez saisir le matricule fiscal du fournisseur!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telephone"
            label="Téléphone"
            rules={[{ required: true, message: 'Veuillez saisir le téléphone du fournisseur!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Veuillez saisir l\'email du fournisseur!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="page_facebook"
            label="Page Facebook"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adresse"
            label="Adresse"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Fournisseurs;
