// src/modules/client/pages/AboutPage.jsx
import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const AboutPage = () => (
  <Layout style={{ padding: '24px', backgroundColor: '#FFFFFF' }}>
    <Content style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
      <Title level={2}>À propos de KUBO.TN</Title>
      <Paragraph>
        KUBO.TN est une plateforme B2C dédiée aux boutiques artisanales. Nous nous concentrons sur la mise en avant des produits artisanaux, en offrant aux consommateurs une large gamme de produits uniques et faits main.
      </Paragraph>
      <Paragraph>
        Notre mission est de promouvoir l'artisanat local et de fournir une plateforme où les artisans peuvent vendre leurs créations directement aux consommateurs. Chez KUBO.TN, nous croyons en la qualité et l'authenticité des produits faits à la main, et nous nous engageons à soutenir les artisans dans leur parcours entrepreneurial.
      </Paragraph>
      <Paragraph>
        Rejoignez-nous dans notre aventure et découvrez des produits artisanaux exceptionnels qui racontent une histoire. Chaque article sur KUBO.TN est fabriqué avec passion et dévouement, reflétant le talent et l'expertise de nos artisans.
      </Paragraph>
    </Content>
  </Layout>
);

export default AboutPage;
