// src/modules/admin/layout/Content.js
import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const CustomContent = () => {
  return (
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 'calc(100vh - 112px)',
        overflowY: 'auto',
        backgroundColor: '#fff',
      }}
    >
      <Outlet />
    </Content>
  );
};

export default CustomContent;
