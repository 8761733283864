// src/modules/client/layout/ConfigProvider.jsx
import React from 'react';
import { ConfigProvider } from 'antd';
import { theme } from 'antd';

const { useToken } = theme;

const CustomConfigProvider = ({ children }) => {
  const { token } = useToken();
  return (
    <ConfigProvider
      theme={{
        token: {
          ...token,
          colorPrimary: '#1A0D03',
          fontFamily: 'Georgia, serif',
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default CustomConfigProvider;
